@import "./partials/colors";

.header_heading {
  bottom: 20vh;
  left: 50%;
  top: 50%;
  max-width: 35rem;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);

  .hashtag_text {
    color: $hashtag-grey;
  }
}

.below_header_container_dark {
  background-color: $background-light-grey;
}

.what_section {
  display: flex;
  justify-content: center;

  .what_section_text {
    max-width: 50rem;
    text-align: center;
  }
}

.values_section {
  background-color: $text-grey;
  padding-bottom: 6rem;
  padding-top: 8rem;

  .values_card {
    padding: 2rem 1rem;

    .icon_container {
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;

      div {
        align-items: center;
        background-color: $background-light-grey;
        border-radius: 50%;
        color: $black;
        display: flex;
        font-size: 2rem;
        height: 4rem;
        justify-content: center;
        min-width: 4rem;
        overflow: hidden;
        width: 4rem;
      }
    }

    .content_container {
      max-width: 40rem;
    }
  }
}

.growth_section {
  display: flex;
  justify-content: center;

  .growth_item_container {
    margin-top: 5.25rem;
    max-width: 45rem;
    text-align: left;
    width: 100%;

    img {
      padding-right: 2rem;
      width: 100%;
    }
  }
}

.social_section {
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  .social_icons_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .icon_btn {
      background-color: $background-light-grey;
      border-radius: 0.75rem;
      margin: 1rem 0.375rem 0;
      max-width: 16rem;
      min-width: 16rem;
      padding: 1rem 1.875rem;
      text-align: center;

      img {
        height: 1.3rem;
        margin-right: 0.6rem;
        vertical-align: auto;
      }

      span {
        color: $white;
        font-weight: 500;
      }
    }
  }
}

.perks_section {
  background-color: $text-grey;
  margin-bottom: 0;
  padding-bottom: 6rem;
  padding-top: 8rem;

  .section_heading {
    display: flex;
    justify-content: center;

    h1 {
      max-width: 50rem;
    }
  }

  .perks_container {
    display: flex;
    justify-content: center;

    .perks_item_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 75rem;

      .perks_item {
        background-color: $card-background-grey;
        border-radius: 0.75rem;
        flex-basis: 33.333333%;
        margin: 1rem;
        max-width: 22.5rem;
        min-width: 22.5rem;
        overflow: hidden;
        padding: 0;
        text-align: center;

        img {
          height: 10rem;
          width: 100%;
        }

        p {
          margin-bottom: 0;
          padding: 2rem;
        }
      }
    }
  }
}

.gadgets_section {
  background-repeat: no-repeat;
  background-position: left;
  background-size: 45%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 10rem;
  padding-top: 10rem;
}

.memory_section {
  background-color: $text-grey;
  margin: 0 auto;
  padding: 8rem 0 8rem 0;
  width: 100%;

  .section_heading {
    display: flex;
    justify-content: center;

    h1 {
      max-width: 40rem;
    }
  }

  .memory_container {
    align-items: stretch;
    display: flex;
    margin-bottom: 4rem;
    margin-top: 0.75rem;
    overflow-x: scroll;
    padding: 0 11.25rem;
    scrollbar-width: none;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .memory_card {
      background: $card-background-grey;
      border-radius: 0.6rem;
      margin: 0 1rem;
      max-width: 28.75rem;
      min-width: 26rem;
      padding: 1.25rem 1.25rem 1.75rem;

      img {
        height: auto;
        width: 100%;
      }
    }

    .memory_date {
      color: $white;
      float: right;
      font: 400 3.75rem/3.75rem Chilldranety;
      margin: 0 1.5rem 0 0;
      transform: translateY(-20px) rotate(-8.06deg);
    }
  }
}

.video_section {
  padding-bottom: 2rem;

  .section_heading {
    display: flex;
    justify-content: center;

    h1 {
      max-width: 60rem;
    }
  }

  .video_container {
    padding-top: 56.25%;
    position: relative;

    iframe {
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}

@media (max-width: 1399px) {
  .gadgets_section {
    background-size: 50%;
  }
}

@media (max-width: 1199px) {
  .values_section {
    .values_card {
      .icon_container {
        justify-content: flex-start;
      }
    }
  }

  .gadgets_section {
    background-size: 55%;
  }
}

@media (max-width: 991px) {
  .gadgets_section {
    background-position: left bottom;
    background-size: 100%;
    padding-bottom: 45rem;
    padding-top: 5rem;
    text-align: center;

    .text_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      h1 {
        width: 100%;
      }

      p {
        max-width: 30rem;
      }
    }
  }

  .video_section {
    .video_container {
      padding-top: 56.25%;
      position: relative;

      iframe {
        height: 70%;
        width: 70%;
      }
    }
  }
}

@media (max-width: 767px) {
  .values_section {
    .values_card {
      padding: 1.5rem 0;
      text-align: center;

      .icon_container {
        justify-content: center;
      }
    }
  }

  .gadgets_section {
    padding-bottom: 40rem;
    padding-top: 5rem;
  }

  .memory_section {
    .memory_container {
      padding: 0 8rem;

      .memory_card {
        max-width: 24rem;
        min-width: 20rem;
      }

      .memory_date {
        font: 400 3rem/4rem Chilldranety;
      }
    }
  }
}

@media (max-width: 575px) {
  .header_heading {
    width: 90%;
  }

  .growth_section {
    .growth_item_container {
      margin-top: 2.5rem;
      text-align: center;

      img {
        width: 30%;
        padding-bottom: 1.5rem;
        padding-right: 0;
      }
    }
  }

  .gadgets_section {
    background-size: 32rem;
    padding-bottom: 26rem;
    padding-top: 5rem;
  }

  .memory_section {
    .memory_container {
      padding: 0 4rem 0 2rem;

      .memory_card {
        margin: 0 0.5rem;
        max-width: 24rem;
        min-width: 20rem;
      }

      .memory_date {
        font: 400 3rem/4rem Chilldranety;
      }
    }
  }

  .video_section {
    .video_container {
      padding-top: 56.25%;
      position: relative;

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
}
